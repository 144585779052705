import "@fontsource/sen/400.css"; // Weight 400 with all styles included.
import "@fontsource/sen/700.css"; // Weight 700 with all styles included.
import "@popperjs/core/dist/esm/popper.js";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "./src/styles/app.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-clock/dist/Clock.css";
import 'aos/dist/aos.css';

const $ = require("jquery");

export const onClientEntry = () => {
  $(".services-nav").hide();
  $(".about-nav").hide();
  $(".solutions-nav").hide();
};

export const onRouteUpdate = () => {
  $(document).ready(function () {
    // window.dataLayer = window.dataLayer || [];
    // function gtag() {
    //   dataLayer.push(arguments);
    // }
    // gtag("js", new Date());
    // gtag("config", "G-4JLHW703S2");
    // [];
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);

    })
    (window, document, "script", "dataLayer", "GTM-TV82L29");
    
    $("#overlay").animate({ opacity: 1.0 }, 500);
    $("#overlay").animate({ opacity: 0 }, 500);
    $("#overlay").delay(500).fadeOut();

    $(".x").click(function () {
      $(this).toggleClass("open");
    });

    $(".services-nav").hide();
    $(".about-nav").hide();
    $(".solutions-nav").hide();
    $(".insights-nav").hide();

    $(".about-link").mouseenter(function () {
      $(".about-nav").show();
      $(".services-nav").fadeOut();
      $(".solutions-nav").fadeOut();
      $(".insights-nav").fadeOut();

    });

    $(".about-nav").mouseleave(function () {
      $(".about-nav").fadeOut();
    });

    $(".solutions-link").mouseenter(function () {
      $(".solutions-nav").show();
      $(".services-nav").fadeOut();
      $(".about-nav").fadeOut();
      $(".insights-nav").fadeOut();
    });

    $(".solutions-nav").mouseleave(function () {
      $(".solutions-nav").fadeOut();
    });

    $(".services-link").mouseenter(function () {
      $(".services-nav").show();
      $(".about-nav").fadeOut();
      $(".solutions-nav").fadeOut();
      $(".insights-nav").fadeOut();
    });

    $(".services-nav").mouseleave(function () {
      $(".services-nav").fadeOut();
    });

    $(".career-link").mouseenter(function () {
      $(".services-nav").fadeOut();
      $(".about-nav").fadeOut();
      $(".solutions-nav").fadeOut();
      $(".insights-nav").fadeOut();

    });

    $("body").mouseenter(function () {
      $(".services-nav").fadeOut();
      $(".about-nav").fadeOut();
      $(".solutions-nav").fadeOut();
      $(".insights-nav").fadeOut();
    });

    $(".insights-link").mouseenter(function () {
      $(".insights-nav").show();
      $(".services-nav").fadeOut();
      $(".about-nav").fadeOut();
      $(".solutions-nav").fadeOut();
    });

    $(".insights-nav").mouseleave(function () {
      $(".insights-nav").fadeOut();
    });


  });

  $(document).scroll(function () {
    var scroll = $(window).scrollTop();

    //>=, not <=
    if (scroll >= 200) {
      //clearHeader, not clearheader - caps H
      $("nav").addClass("nav-shadow");
    } else {
      $("nav").removeClass("nav-shadow");
    }
  });

  $(document).ready(function () {
    $(".login-button-sso").click(function () {
      var domain = "https://tarademo.deerhold.com/";
      var isValid = false;
      var url;
      var username = $("#username").val();
      var password = $("#password").val();
      var correctTaraLink =
        domain +
        "login/ZjdiN2RmMDMwYmRiMDNhZmYyZDc3ZWUzNDFjOWQ4ZDM6MDgvMTYvMTk5MQ==";
      var incorrectTaraLink =
        domain +
        "login/ZccvjdiN2RmMDMwYmRiMDNhZmYyZDc3ZWUzNDFjOWQ4ZDM6MDgvMTYvMTk5MQ==";
      if (username == "") {
        $("#username").addClass("error-sso");
        isValid = false;
      } else {
        isValid = true;
        $("#username").removeClass("error-sso");
      }

      if (password == "") {
        isValid = false;
        $("#password").addClass("error-sso");
      } else {
        isValid = true;
        $("#password").removeClass("error-sso");
      }

      if (username == "deerholdTaraSso" && password == "Deerhold@3214") {
        isValid = true;
        url = correctTaraLink;
      } else if (
        username == "deerholdTaraSso1" &&
        password == "Deerhold@3214"
      ) {
        url = incorrectTaraLink;
        isValid = true;
      } else {
        $("#password").addClass("error-sso");
        $("#username").addClass("error-sso");
        isValid = false;
      }

      if (isValid) {
        $("#linkTaraSso").attr("href", url);
        $("#ssoLogin").hide();
        $("#tara_sson").show();
        return false;
      } else {
        return false;
      }
    });
  });
};
